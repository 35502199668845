/* @import url("https://fonts.googleapis.com/css2?family=Lobster+Two:wght@100;400;500;700");
@import url("https://fonts.googleapis.com/css2?family=Gantari:wght@100;400;500;700");
@import url("https://fonts.googleapis.com/css2?family=Suez+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Secular+One&family=Suez+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;400;500;700;800&family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yatra+One&display=swap'); */


html,
body {
  padding: 0 !important;
  margin: 0 !important;
  touch-action: manipulation;
  /* background-color: #000; */
}
a {
  color: inherit;
  text-decoration: none !important;
}

.fullWidth {
  width: 100% !important;
}

.mobile_field {
  background: #343434;
  border: 1px solid #5D5D5D;
  padding: 10px 15px;
}

.mobile_field .PhoneInput {
  width: 100%;
}

.mobile_field .PhoneInputInput, .mobile_field .PhoneInputInput:focus {
  background: #343434;
  /* border: 2px solid #5D5D5D; */
  color: #fff;
  /* margin-left: 10px; */
  outline: none;
}

.mobile_field .PhoneInputCountryIcon {
  box-shadow: none;
}

.mobile_field .PhoneInputCountrySelectArrow {
  display: none;
}



.mobile_field_light {
  background: #fff;
  border: 1px solid #7B7B7B !important;
  padding: 10px;
  border-radius: 10px;
}

.mobile_field_light .PhoneInput {
  width: 100%;
}

.mobile_field_light .PhoneInputInput, 
.mobile_field_light .PhoneInputInput:focus {
  background: #fff;
  /* border: 2px solid #5D5D5D; */
  color: #7B7B7B;
  /* margin-left: 10px; */
  outline: none;
}

.mobile_field_light .PhoneInputCountryIcon {
  box-shadow: none;
}

.mobile_field_light .PhoneInputCountrySelectArrow {
  display: none;
}



.mobile_field_light_2 {
  background: #fff;
  border: 1px solid #C4C4C4 !important;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.mobile_field_light_2:hover {
  border: 1px solid black !important;
}

.mobile_field_light_2 .PhoneInput {
  width: 100%;
}

.mobile_field_light_2 .PhoneInputInput, 
.mobile_field_light_2 .PhoneInputInput:focus {
  background: #fff;
  /* border: 2px solid #5D5D5D; */
  color: #000;
  /* margin-left: 10px; */
  outline: none;
}

.mobile_field_light_2 .PhoneInputCountryIcon {
  box-shadow: none;
}

.mobile_field_light_2 .PhoneInputCountrySelectArrow {
  display: none;
}

/* --------------------------------------- COPIED FROM CLUBR.IN ------------------------------------------ */

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
a {
  text-decoration: none;
  font-family: "DM Sans", sans-serif;
}
* {
  box-sizing: border-box;
}
:root {
  --swiper-theme-color: #0de46c;
  --swiper-navigation-size: 25px !important;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.main_sec {
  padding-top: 3rem !important;
  padding-bottom: 1rem !important;
  height: auto !important;
}

.bg-dark {
  background-color: #222222 !important;
  transition: 0.35s ease-in-out;
  padding-bottom: 4px;
  border-bottom: 1px solid #555;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: #0de46c;
}

.custom-form-control {
  background-color: #1d1d1d !important;
  border-radius: 10px !important;
  width: 100%;
  height: 50px;
  outline: 0;
  border: 1px solid rgb(93, 93, 93) !important;
  margin: 15px auto 0 auto;
  display: block;
  color: #fff;
  font-family: "DM Sans", sans-serif;
  letter-spacing: 1px;
  padding: 1rem;
  font-size: 18px;
}

/* input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {  
  color: #fff;
  background-color: #1D1D1D;
  font-size: 14px;
} */

/* input[type="tel"],
input[type="tel"]:focus {
  background-color: #1d1d1d;
  border-radius: 0;
  height: unset;
  outline: 0;
  border: 0;
  display: block;
  color: #ffffff99;
  font-family: "DM Sans", sans-serif;
  width: 100%;
}
input[type="tel"]::not(:last-child) {
  margin: 0;
} */

.themeBtnAlt {
  background-color: transparent !important;
  color: #0de46c !important;
  opacity: 1;
  border: 1px solid #0de46c !important;
  /* border-radius: 25px !important; */
}

.themeBtnAlt:hover {
  color: #000 !important;
  background: linear-gradient(
    0deg,
    #37f81b -7.69%,
    #24f849 28.45%,
    #00faa0 96.92%
  );
}

.themeBtnNew {
  background-color: #1d1d1d !important;
  color: #24f849 !important;
  opacity: 1;
  border: 1px solid #1d1d1d !important;
  /* border-radius: 25px !important; */
  box-shadow: none;
}

.bannerPatch {
  min-height: 65vh;
  margin-left: auto;
  border-radius: 0 25px 25px 0;
  display: block;
  align-items: center;
  height: 100%;
  max-height: auto !important;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover !important;
}

/*************  Card Css Start *************/

.img_sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  width: 250px;
}
.img_sticky img {
  width: 350px;
  border-radius: 25px;
}

.eventSearchBox {
  border-radius: 20px !important;
  padding: 10px 13px;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: block;
  background-color: #222222;
  border-bottom: 1.5px solid #222222;
  color: #ffffff99;
}
.eventSearchBox:focus {
  outline: #222222;
}

.headerSearchBox {
  border-radius: 25px;
  padding: 10px 35px 10px 18px;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: block;
  background-color: #2e2d2d;
  color: #ffffff99;
  width: 100%;
  max-width: 50%;
}

.headerSearchBox:focus {
  outline: #1d1d1d;
}

.loadingHeader {
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  right: 7px;
  top: 7px;
}

.overlay-box {
  background-color: rgba(0, 3, 0, 0.9);
  text-align: center;
  height: 100%;
  width: 100%;
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-self: center;
  min-height: 100%;
  justify-content: center;
  align-items: center;
}

.overlay-box {
  color: #ffffff99;
  letter-spacing: 1px;
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(30, 30, 30);
  opacity: 0.99;
  background-size: 300px;
  color: #d2d2d2;
  letter-spacing: 1.5px;
}
/* .react-tel-input .flag-dropdown,
.react-tel-input .selected-flag:active,
.react-tel-input .selected-flag:focus,
.react-tel-input .selected-flag:hover,
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
  border: none !important;
} */

.react-tel-input .form-control {
  /* border: 1px solid rgb(93, 93, 93);
  width: 100% !important;
  height: 50px !important;
  border-radius: 10px !important;
  color: #fff;
  letter-spacing: 1.5px !important;
  background-color: #1d1d1d;
  font-size: 18px; */
}

.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none;
}

.qrImage span {
  border-radius: 30px;
  box-shadow: 0px 0px 16px -2px #16fb48e5;
}

.themeBtn {
  border: 0;
  /* border-radius: 50px; */
  transition: 0.3s;
  font-size: 12px;
  font-weight: 600;
  color: #000;
  background-color: #61fc67;
}

.themeBtn:hover {
  border: 0;
  /* border-radius: 50px; */
  transition: 0.3s;
  /* font-size: 12px; */
  font-weight: 600;
  /* color: #000; */
  /* background-color: #61fc67; */
  opacity: 0.9;
}

.themeBtnDisabled {
  border: 0 !important;
  /* border-radius: 50px !important; */
  transition: 0.3s;
  font-size: 12px;
  font-weight: 600 !important;
  color: #888 !important;
  background-color: #444 !important;
}

.footer .mobile_footer {
  display: none !important;
}

.slick-slide {
  padding: 0px;
}

.theme-link {
  display: flex;
  align-self: center;
}

.theme-link:hover {
  cursor: pointer;
  background-image: linear-gradient(
    0deg,
    #37f81b -7.69%,
    #24f849 28.45%,
    #00faa0 96.92%
  ) !important;
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.swiper-slide {
  background-size: cover;
}

.desktopMode {
  display: block !important;
}

.mobileMode {
  display: none !important;
}

.stickyPosition {
  position: sticky !important;
  top: 8.25% !important;
  padding: 15px !important;
  border-radius: 5px !important;
  background: #171919;
}

@media screen and (max-width: 767px) {
  .desktopMode {
    display: none !important;
  }
  .mobileMode {
    display: block !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
  .main_sec {
    padding-top: 1rem !important;
  }
  .stickyPosition {
    top: 6.5% !important;
    padding: 10px !important;
    border-radius: 1px !important;
  }
}

:root {
  --PhoneInputCountryFlag-aspectRatio: 1.333;
}

/* .react-tel-input .flag-dropdown{
  right: 36px !important

}

.react-tel-input .form-control{
  padding-left: 15px;
} */

.tele-container ul.country-list {
  z-index: 999;
  top: -50px;
  left: 10px;
  right: auto;
  position: relative !important;
  height: 125px;
  padding: 15px;
  background-color: #212121;
  font-family: "DM Sans", sans-serif;
  border-radius: 5px;
  width: 100%;
}
.tele-container ul.country-list .country:hover,
.tele-container ul.country-list .country.highlight {
  background-color: #1d1d1d;
}

/* @media screen and (max-width: 600px){
  .main_sec {
    padding-top: 2.5rem !important;
  }
} */

.rippleBtn span {
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 0 0 15px;
  position: relative;
  align-items: center;
  border: 2px solid #61fc6e;
  background: #61fc6e;
}

.rippleBtn span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: 50%;
  z-index: 1;
  animation: ripple 1.5s ease-out infinite;
  background: #61fc6e;
}

.rippleBtn span {
  border: 2px solid #61fc6e;
  background: #61fc6e;
}

.rippleBtn span::before {
  animation-delay: 0.2s;
}

.rippleBtnAlt span {
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 0 0 15px;
  position: relative;
  align-items: center;
  border: 2px solid #151414;
  background: #151414;
}

.rippleBtnAlt span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: 50%;
  z-index: 0;
  animation: ripple 1.5s ease-out infinite;
  background: #151414;
}

.rippleBtnAlt span {
  border: 2px solid #151414;
  background: #151414;
}

.rippleBtnAlt span::before {
  animation-delay: 0.2s;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}

.searchBox {
  background: #1d1d1d;
  border-radius: 30px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #d2d2d2;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #5d5d5d;
}

.searchBox:focus {
  outline: none;
}

@media only screen and (max-width: 767px) {
  .rippleBtn span {
    width: 30px;
    height: 30px;
    margin: 0 0 0 5px;
  }
  .searchBox {
    width: 100%;
  }
}

.locationMenuItem {
  background-color: hsl(0, 0%, 11%) !important;
  padding: 0;
}
.autoWidthSlider .swiper-slide {
  width: auto !important;
}

.scroll-to-top {
  background-color: #1d1d1d !important;
  border: 1px solid #555 !important;
  color: #d2d2d2 !important;
  border-radius: 30px !important;
}

.react-modal-sheet-container {
  display: none !important;
}
@media screen and (max-width: 767px) {
  .react-modal-sheet-container {
    display: block !important;
  }
}

.reactOtp {
  width: 100%;
  justify-content: space-between;
}
.reactOtp input {
  background: #343434;
  border: 1px solid #5d5d5d;
  /* border-radius: 10px;   */
  height: 45px;
  width: 40px !important;
  box-shadow: 0px 3px 10px #111;
  color: #fff !important;
  padding: 5px;
}

.Toastify__toast-theme--light {
  background-color: #151414 !important;
  border: 1px solid #5d5d5d !important;
  border-radius: 20px;
  /* height:50px; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #d2d2d2;
  letter-spacing: 1px;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 200;
  padding: 20px;
  z-index: 1300 !important;
  opacity: 1;
}

.Toastify__toast-body {
  text-align: left;
}

.Toastify__close-button {
  color: #fff;
  opacity: 1;
  font-weight: 100;
}
.Toastify__progress-bar--error {
  background: #e74c3c !important;
  height: 2px;
}
.Toastify__progress-bar--success {
  background: #07bc0c !important;
  height: 2px;
}

.hidden {
  display: none !important;
  transition: 0.5s ease-in-out;
  transition-delay: 1000ms;
}
.visible {
  display: flex !important;
  transition: 0.5s ease-in-out;
  transition-delay: 10000ms;
}

.aws-btn {
  --button-default-height: 50px !important;
  --button-default-font-size: 14px !important;
  --button-default-border-radius: 50px !important;
  --button-horizontal-padding: 50px !important;
  --button-raise-level: 7px !important;
  --button-hover-pressure: 1.5 !important;
  --transform-speed: 0.05s !important;
  --button-primary-color: #61fc6e !important;
  --button-primary-color-dark: #efff3d !important;
  --button-primary-color-light: #000 !important;
  --button-primary-color-hover: #6ff57b !important;
  --button-primary-color-active: #6ff57b !important;
  --button-primary-border: none !important;
}

.aws-btn--primary .aws-btn__content {
  border: 1px solid #000 !important;
}


.NavBarThreeSlider .slick-slide {
  width: 400px;
  height: 400px !important;
  transition: transform .4s;
  position: relative; 
}

.NavBarThreeSlider .slick-slide {
  width: 400px;
  height: 400px !important;
  transition: transform .4s;
  position: relative; 
}

.NavBarThreeSlider .slick-slide:after {
  content:'';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  transition: transform .4s;
}

.NavBarThreeSlider .slick-slide {
  transform: scale(0.7)  translate(640px);
}

.NavBarThreeSlider .slick-slide.slick-center + .slick-slide {
  transform: scale(0.8) translate(-250px);
  z-index: 10;
}

.NavBarThreeSlider .slick-slide.slick-center + .slick-slide + .NavBarThreeSlider .slick-slide {
  transform: scale(0.7)  translate(-640px);
  z-index: 5;
}

.NavBarThreeSlider .slick-slide.slick-active {
  transform: scale(0.8) translate(250px);
}

.NavBarThreeSlider .slick-slide.slick-center {
  /* margin: 0 -10%; */
  transform: scale(1);
  z-index: 30;
}

.NavBarThreeSlider .slick-center:after {
  opacity: 0;
}

.error{
  color:red;
  font-size: 14px;
}


.myvideo {
  width: 100%;
  height: 100vh;
}

.myvideo video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  color: #fff;
}

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000']{
  transition-duration: 2000ms;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.MuiAccordion-root{
  background: transparent !important
}